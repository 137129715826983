import styled from 'styled-components';

import { baseFontFamily } from 'views/services/font-scheme';
import { primaryTextColor, darkPrimaryColor  } from 'views/services/color-scheme';

export const Root = styled.div`
  height: 100%;
  & > button {
    cursor: pointer;
    height: 100%;
    padding: 8px 16px;
    font-family: ${baseFontFamily};
    font-size: 1em;
    color: ${primaryTextColor};
    &:hover, &.active {
      background-color: ${darkPrimaryColor};
    }
  }
`;