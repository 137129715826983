import React, { FC } from 'react';

import { PercentageCounter } from 'views/components/counters';
import { Root, RootProps } from './index.styled';

const PieChart: FC<RootProps> = ({ value }) => {
  return (
    <Root value={value}>
      <svg viewBox="0 0 100 100">
        <circle cx="50" cy="50" r="46" />
        <circle cx="50" cy="50" r="46" />
      </svg>
      <p><PercentageCounter value={value} /></p>
    </Root>
  );
};

export { PieChart };