import styled, { keyframes } from 'styled-components';

import { baseFontFamily } from 'views/services/font-scheme';
import { textColor, primaryColor, clearPrimaryColor } from 'views/services/color-scheme';

export interface RootProps {
  value: number;
}

const radius = 50;
const circumference = 2 * Math.PI * radius;

function getOpening(value: number) {
  return keyframes`
    0% {
      stroke-dashoffset: ${circumference};
    }
    100% {
      stroke-dashoffset: ${circumference - (circumference * value / 100)};
    }
  `;
}

export const Root = styled.div<RootProps>`
  & > svg{
    transform: rotate(-90deg);
    width: 5em;
    height: 5em;
    & > circle {
      fill: none;
      stroke: ${clearPrimaryColor};
      stroke-width: 8;
      stroke-dasharray: ${circumference};
      stroke-dashoffset: 0;
      stroke-linecap: round;
      &:nth-child(2) {
        animation: ${p => getOpening(p.value)} 1s ease-in-out;
        stroke-dashoffset: ${p => circumference - (circumference * p.value / 100)};
        stroke: ${primaryColor};
      }
    }
  }
  position: relative;
  & > p {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-family: ${baseFontFamily};
    font-size: 1em;
    color: ${textColor};
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;