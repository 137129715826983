import React, { useState, useEffect } from 'react';

import { PosReport } from 'models/entities/pos-report';
import { DishCategory } from 'models/entities/dish-category';
import { MenuCategory } from 'models/entities/menu-category';
import { OpenaiChatEditor } from 'models/entities/openai';
import { Model, DataToSearch } from './index.model';

import { useAuth } from 'views/services/auth';
import { useMemory } from 'views/services/memory';
import { useBusinessCode } from 'views/services/hooks';
import { useModal, ModalResult, ExportReportTarget } from 'views/services/modal';
import { InterfaceLoading } from 'views/components/interface-loading';
import { NoInterfaceData } from 'views/components/no-interface-data';
import { ContentLoading } from 'views/components/content-loading';
import { Header } from 'views/components/header';
import { PeriodToolItem } from 'views/components/period-tool-item';
import { MoneyCounter } from 'views/components/counters';
import { PieChart } from 'views/components/pie-chart';
import { Root, Frame, Content, Toolbar, Summary, MenuCategories, Reports, DishCategories, Report, TotalReport, Number, LargeNumber } from './index.styled';

const Business = () => {

  const auth = useAuth();
  const memory = useMemory();
  const code = useBusinessCode();
  const modal = useModal();
  const [model, setModel] = useState(new Model());

  function search(data: DataToSearch) {
    setModel(model.startSearching());
    model.search(data).then(it => setModel(it))
  }

  function showItemReport(menuCategory: MenuCategory, dishCategory?: DishCategory) {
    if (!model.business) return;
    const target = model.itemReports.find(it => it.menuCategoryId === menuCategory.id);
    const other = { business: model.business, menuCategory, category: dishCategory };
    modal.open('show-dish-item-report', { target, other });
  }

  function importReport() {
    modal.open('import-report', { target: model.build(), strict: true, tell: apply });
  }

  function apply(result: ModalResult) {
    if (!result.target) return;
    if (!(result.target instanceof PosReport)) return;
    const report = result.target as PosReport;
    switch (result.state) {
      case 'imported': setModel(model.calc(report)); break;
    }
  }

  function exportReport() {
    const target = new ExportReportTarget({ salesReport: model.salesReport });
    modal.open('export-report', { target });
  }

  function onChat() {
    if (!model.chat) return;
    if (!auth.user.openaiAccount) return;
    const target = new OpenaiChatEditor({ original: model.chat, account: auth.user.openaiAccount });
    modal.open('create-chat-completion', { target, strict: true, tell: applyChat });
  }

  function applyChat(result: ModalResult) {
    if (!result.target) return;
    if (!(result.target instanceof OpenaiChatEditor)) return;
    const chat = (result.target as OpenaiChatEditor).original;
    switch (result.state) {
      case 'chatted': setModel(model.apply({ chat })); break;
    }
  }

  function watchAuth() {
    if (!auth.ok) return;
    if (!code) return;
    const businessScope = memory.get('business-scope');
    businessScope ? setModel(model.init(businessScope)) : model.readBusiness(code).then(model => setModel(model));
  }

  function watchBusiness() {
    if (!model.business) return;
    model.read().then(model => setModel(model));
  }

  useEffect(watchAuth, [auth.ok, code]);
  useEffect(watchBusiness, [model.business]);

  return (
    <Root>
      <Header mode={'business'} businesses={model.businesses} business={model.business} onImportReport={() => importReport()} onExportReport={() => exportReport()} onChat={onChat} disabledAction={model.disabledAction} />
      {!model.business || !model.dishItemCollections ? <InterfaceLoading /> : (
        <Content>
          <ContentLoading loading={model.searching}>
            <Frame>
              <Toolbar>
                <PeriodToolItem period={model.conditions.period} onEdit={period => search({ period })} />
              </Toolbar>
              <Summary>
                <ol>
                  <li>
                    <LargeNumber>
                      <h4>Cost Total</h4>
                      <div>
                        <p><MoneyCounter currency={model.business.currency} value={model.businessReport.foodCost} /></p>
                      </div>
                    </LargeNumber>
                  </li>
                  <li>
                    <LargeNumber>
                      <h4>Cost Rate</h4>
                      <div className="chart">
                        <PieChart value={model.businessReport.costRate} />
                      </div>
                    </LargeNumber>
                  </li>
                  <li>
                    <LargeNumber>
                      <h4>Total Sales</h4>
                      <div>
                        <p><MoneyCounter currency={model.business.currency} value={model.businessReport.totalSales} /></p>
                      </div>
                    </LargeNumber>
                  </li>
                  <li>
                    <LargeNumber>
                      <h4>Total Share</h4>
                      <div className="chart">
                        <PieChart value={model.businessReport.totalShare} />
                      </div>
                    </LargeNumber>
                  </li>
                </ol>
              </Summary>
              {!model.business.menuCategories.documents.length ? <NoInterfaceData>No Dashboard</NoInterfaceData> : (
                <>
                  <MenuCategories columnNumber={model.business.menuCategories.documents.length}>
                    <ol>
                      {model.business.menuCategories.documents.map((it, i) => (
                        <li key={i}>
                          <h2>{it.original.name}</h2>
                        </li>
                      ))}
                    </ol>
                  </MenuCategories>
                  <Reports columnNumber={model.business.menuCategories.documents.length}>
                    <ol>
                      {model.business.menuCategories.documents.map((menuCategory, i) => (
                        <li key={i}>
                          <DishCategories>
                            <ol>
                              {model.menuCategoryReports?.filter(report => report.menuCategoryId === menuCategory.id).map((report, j) => (
                                <li key={j}>
                                  <TotalReport onClick={() => showItemReport(menuCategory)}>
                                    <h3>All</h3>
                                    <ol>
                                      <li>
                                        <Number>
                                          <h4>Cost Total</h4>
                                          <div>
                                            <p><MoneyCounter currency={model.business!.currency} value={report.foodCost} /></p>
                                          </div>
                                        </Number>
                                      </li>
                                      <li>
                                        <Number>
                                          <h4>Cost Rate</h4>
                                          <div className="chart">
                                            <PieChart value={report.costRate} />
                                          </div>
                                        </Number>
                                      </li>
                                      <li>
                                        <Number>
                                          <h4>Total Sales</h4>
                                          <div>
                                            <p><MoneyCounter currency={model.business!.currency} value={report.totalSales} /></p>
                                          </div>
                                        </Number>
                                      </li>
                                      <li>
                                        <Number>
                                          <h4>Total Share</h4>
                                          <div className="chart">
                                            <PieChart value={report.totalShare} />
                                          </div>
                                        </Number>
                                      </li>
                                    </ol>
                                  </TotalReport>
                                </li>
                              ))}
                              {model.categoryReports?.find(report => report.menuCategoryId === menuCategory.id)?.records.map((record, j) => (
                                <li key={j}>
                                  <Report onClick={() => showItemReport(menuCategory, record.category)}>
                                    <h3>{record.category.original.name}</h3>
                                    <ol>
                                      <li>
                                        <Number>
                                          <h4>Cost Total</h4>
                                          <div>
                                            <p><MoneyCounter currency={model.business!.currency} value={record.foodCost} /></p>
                                          </div>
                                        </Number>
                                      </li>
                                      <li>
                                        <Number>
                                          <h4>Cost Rate</h4>
                                          <div className="chart">
                                            <PieChart value={record.costRate} />
                                          </div>
                                        </Number>
                                      </li>
                                      <li>
                                        <Number>
                                          <h4>Total Sales</h4>
                                          <div>
                                            <p><MoneyCounter currency={model.business!.currency} value={record.totalSales} /></p>
                                          </div>
                                        </Number>
                                      </li>
                                      <li>
                                        <Number>
                                          <h4>Total Share</h4>
                                          <div className="chart">
                                            <PieChart value={record.totalShare} />
                                          </div>
                                        </Number>
                                      </li>
                                    </ol>
                                  </Report>
                                </li>
                              ))}
                            </ol>
                          </DishCategories>
                        </li>
                      ))}
                    </ol>
                  </Reports>
                </>
              )}
            </Frame>
          </ContentLoading>
        </Content>
      )}
    </Root>
  );

};

export default Business;
