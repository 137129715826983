import { Period } from 'models/value-objects/period';

type Data = {
  period: Period;
};

class Model {

  readonly period: Period;
  readonly active: boolean;

  constructor(data: Data) {
    this.period = data.period;
    this.active = false;
  }

  load(period: Period): this {
    return Object.assign(Object.create(this.constructor.prototype), { ...this, period });
  }

  toggle(): this {
    const active = !this.active;
    return Object.assign(Object.create(this.constructor.prototype), { ...this, active });
  }

  off(): this {
    return Object.assign(Object.create(this.constructor.prototype), { ...this, active: false });
  }

}

export { Model };