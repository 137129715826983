import React, { FC, useState, useRef, useEffect, MouseEvent  } from 'react';

import { Model } from './index.model';

import { useDropdown, PeriodToolProps } from 'views/services/dropdown';
import { Root } from './index.styled';

const PeriodToolItem: FC<PeriodToolProps> = (props) => {

  const [model, setModel] = useState(new Model({ period: props.period }));
  const dropdown = useDropdown();
  const button = useRef<HTMLButtonElement>(null);

  function onClick(e: MouseEvent<HTMLButtonElement>) {
    const target = e.currentTarget;
    const button = target.getBoundingClientRect();
    const x = button.left;
    const y = button.top + button.height;
    const onClose = () => setModel(model.off());
    dropdown.toggle('period-tool', { target, point: { x, y }, props, onClose });
    setModel(model.toggle());
  }

  function watchPeriod() {
    if (props.period.isSame(model.period)) return;
    setModel(model.load(props.period));
  }

  useEffect(watchPeriod, [props.period]);

  return (
    <Root>
      <button ref={button} onClick={onClick} className={model.active ? 'active' : ''}>{model.period.label}</button>
    </Root>
  );

};

export { PeriodToolItem };