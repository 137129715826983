import React, { FC } from 'react';

import { Currency } from 'models/value-objects/currency';
import { showMoney } from 'views/services/helpers';

import { useCounter } from '../counter/useCounter';

interface Props {
  value: number;
  currency: Currency;
}

const MoneyCounter: FC<Props> = ({ value, currency }) => {

  const current = useCounter(value);

  return (
    <span>{showMoney(current, currency)}</span>
  );

};

export { MoneyCounter };
