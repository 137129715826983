import styled from 'styled-components';

import { baseFontSize } from 'views/services/font-scheme';
import { darkShadowColor } from 'views/services/color-scheme';

export const Root = styled.div`
  position: relative;
`;

export const Content = styled.div`
  position: relative;
  z-index: 0;
`;

export const Loading = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${darkShadowColor};
  font-size: calc(${baseFontSize} * 1.5);
  display: flex;
  justify-content: center;
  align-items: center;
`;
