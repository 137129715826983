class Model {

  private result: number;
  private start: number;
  private end: number;
  private velocity: number;
  private current: number;
  private timer?: NodeJS.Timer;

  constructor() {
    this.result = 0;
    this.start = 0;
    this.end = 0;
    this.velocity = 0;
    this.current = 0;
  }

  go(value: number, count: (result: number) => void): this {
    if (!isFinite(value)) return this.stop(value);
    this.start = isFinite(this.result) ? this.result : 0;
    this.end = value;
    this.velocity = (this.end - this.start) / (1000 / 100);
    if (!this.velocity) return this.stop();
    this.current = this.start;
    this.run(count);
    return this;
  }

  private run(count: (result: number) => void): this {
    this.timer = setInterval(() => this.next(count), 100);
    return this;
  }

  private next(count: (result: number) => void): this {
    this.current += this.velocity;
    this.result = Math.round(this.current);
    if (this.isMin() || this.isMax()) this.stop();
    count(this.result);
    return this;
  }

  private stop(value?: number): this {
    this.result = value !== undefined ? value : this.end;
    this.velocity = 0;
    clearInterval(this.timer!);
    return this;
  };

  private isMax(): boolean {
    return this.velocity > 0 && this.current >= this.end;
  };

  private isMin(): boolean {
    return this.velocity < 0 && this.current <= this.end;
  };

}

export { Model };