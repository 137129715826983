import styled from 'styled-components';

import { baseFontFamily, baseFontSize } from 'views/services/font-scheme';
import { baseColor, darkBaseColor, textColor, lightTextColor, lineColor, primaryColor, lightPrimaryColor, vividPrimaryColor, primaryTextColor, primaryLineColor, darkPrimaryColor, accentColor } from 'views/services/color-scheme';

import { headerHeight } from 'views/components/header';

interface ColumnNumber {
  columnNumber: number;
}

const columnWidth = '640px';

export const Root = styled.div`
`;

export const Content = styled.div`
  position: relative;
  z-index: 0;
  &::after {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 1px;
    border-top: 1px solid ${darkPrimaryColor};
  }
`;

export const Frame = styled.div`
  position: relative;
  width: 100vw;
  height: calc(100vh - ${headerHeight});
  overflow: scroll;
  background-color: ${darkBaseColor};
`;

export const Toolbar = styled.div`
  z-index: 10;
  position: sticky;
  top: 0;
  left: 0;
  width: 100vw;
  height: ${headerHeight};
  background-color: ${lightPrimaryColor};
  &::after {
    content: '';
    position: absolute;
    z-index: 11;
    top: calc(100% - 1px);
    left: 0;
    width: 100%;
    height: 1px;
    border-bottom: 1px solid ${darkPrimaryColor};
  }
  font-size: calc(${baseFontSize} * 1);
  display: flex;
`;

export const Summary = styled.div`
  position: sticky;
  left: 0;
  width: 100vw;
  background-color: ${lightPrimaryColor};
  padding: 32px 16px;
  box-sizing: border-box;
  & > ol {
    display: flex;
    justify-content: space-between;
    align-items: start;
    & > li {
      flex: 1;
    }
  }
`;

export const MenuCategories = styled.div<ColumnNumber>`
  z-index: 10;
  position: sticky;
  top: ${headerHeight};
  background-color: ${darkPrimaryColor};
  & > ol {
    font-family: ${baseFontFamily};
    font-size: calc(${baseFontSize} * 1);
    min-width: calc(${columnWidth} * ${p => p.columnNumber});
    height: ${headerHeight};
    display: flex;
    & > li {
      flex: 1;
      padding: 0 16px;
      height: 100%;
      background-color: ${darkPrimaryColor};
      color: ${primaryTextColor};
      position: relative;
      &::after {
        content: '';
        pointer-events: none;
        position: absolute;
        top: -0.5px;
        left: -0.5px;
        right: -0.5px;
        bottom: -0.5px;
        border-width: 0 1px;
        border-style: solid;
        border-color: ${primaryLineColor};
      }
      display: flex;
      align-items: center;
    }
  }
`;

export const Reports = styled.div<ColumnNumber>`
  z-index: 0;
  & > ol {
    background-color: ${darkBaseColor};
    min-width: calc(${columnWidth} * ${p => p.columnNumber});
    display: flex;
    justify-content: space-between;
    align-items: start;
    & > li {
      flex: 1;
    }
  }
`;

export const DishCategories = styled.div`
`;

export const Report = styled.div`
  background-color: ${baseColor};
  padding: 16px;
  cursor: pointer;
  position: relative;
  &::after {
    content: '';
    pointer-events: none;
    z-index: 1;
    position: absolute;
    top: -0.5px;
    left: -0.5px;
    right: -0.5px;
    bottom: -0.5px;
    border: 1px solid ${lineColor};
  }
  &:hover {
    background-color: ${lightPrimaryColor};
    &::after {
      z-index: 3;
      border: 1px solid ${vividPrimaryColor};
    }
  }
  & > h3 {
    font-family: ${baseFontFamily};
    font-size: calc(${baseFontSize} * 1);
    color: ${textColor};
  }
  & > ol {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    align-items: start;
    & > li {
      flex: 1;
    }
  }
`;

export const TotalReport = styled(Report)`
  &::after {
    z-index: 2;
    border-color: ${darkPrimaryColor};
  }
`;

export const Number = styled.div`
  & > h4 {
    font-family: ${baseFontFamily};
    font-size: calc(${baseFontSize} * 0.8);
    color: ${lightTextColor};
  }
  & > div {
    margin-top: 16px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    & > p {
      font-family: ${baseFontFamily};
      font-size: calc(${baseFontSize} * 2);
      color: ${textColor};
      white-space: nowrap;
    }
    &.chart {
      font-size: calc(${baseFontSize} * 1);
    }
  }
`;

export const LargeNumber = styled(Number)`
  & > h4 {
    font-size: calc(${baseFontSize} * 1);
    color: ${accentColor};
  }
  & > div {
    & > p {
      font-size: calc(${baseFontSize} * 3);
    }
    &.chart {
      font-size: calc(${baseFontSize} * 1.2);
    }
  }
`;
