import React, { useState, useEffect } from 'react';

import { Model } from './index.model';

const useCounter = (value: number) => {

  const [model] = useState(new Model());
  const [current, setCurrent] = useState(value);

  function watchValue() {
    model.go(value, result => setCurrent(result));
  }

  useEffect(watchValue, [value])

  return current;

};

export { useCounter };