import React, { FC } from 'react';

import { showPercentage } from 'views/services/helpers';

import { useCounter } from '../counter/useCounter';

interface Props {
  value: number;
}

const PercentageCounter: FC<Props> = ({ value }) => {

  const current = useCounter(value);

  return (
    <span>{showPercentage(current)}</span>
  );

};

export { PercentageCounter };
