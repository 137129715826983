import React, { FC, PropsWithChildren } from 'react';

import { LoadingAnimation } from 'views/components/loading-animation';
import { Root, Content, Loading } from './index.styled';

interface Props extends PropsWithChildren {
  loading: boolean;
}

const ContentLoading: FC<Props> = ({ children, loading }) => {
  return (
    <Root>
      <Content>{children}</Content>
      {loading && <Loading><LoadingAnimation background={'black'} /></Loading>}
    </Root>
  );
};

export { ContentLoading };